define('luera/router', ['exports', 'ember', 'luera/config/environment'], function (exports, _ember, _lueraConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _lueraConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('main', { path: 'main' });
    this.route('lektorat', { path: 'lektorat' });
    this.route('translation', { path: 'translation' });
    this.route('author', { path: 'author' });
    this.route('reference', { path: 'reference' });
    this.route('contact', { path: 'contact' });
    this.route('redaktion', { path: 'redaktion' });
    this.route('spiel', { path: 'spiel' });
  });

  exports['default'] = Router;
});