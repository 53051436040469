define('luera/helpers/get', ['exports', 'ember', 'ember-get-helper/helpers/get', 'ember-get-helper/helpers/get-glimmer'], function (exports, _ember, _emberGetHelperHelpersGet, _emberGetHelperHelpersGetGlimmer) {

  var forExport = null;

  if (_ember['default'].Helper) {
    forExport = _emberGetHelperHelpersGetGlimmer['default'];
  } else if (_ember['default'].HTMLBars.makeBoundHelper) {
    forExport = _ember['default'].HTMLBars.makeBoundHelper(_emberGetHelperHelpersGet.getHelper);
  }

  exports['default'] = forExport;
});