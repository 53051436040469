define("luera/components/type-two", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    "catalog": [{ "question": "handy", "answers": [{ "number": 1, "text": "Mobiltelefon" }, { "number": 2, "text": "praktisch/n&uuml;tzlich" }, { "number": 3, "text": "von Hand" }],
      "correct": [false, true, false],
      "solution": "praktisch/n&uuml;tzlich",
      "explaination": "handy bedeutet praktisch/n&uuml;tzlich im Deutschen."
    }, { "question": "become", "answers": [{ "number": 1, "text": "bekommen" }, { "number": 2, "text": "vertragen" }, { "number": 3, "text": "werden" }],
      "correct": [false, false, true],
      "solution": "werden",
      "explaination": "become bedeutet werden im Deutschen." }, { "question": "actually", "answers": [{ "number": 1, "text": "aktualisieren" }, { "number": 2, "text": "tats&auml;chlich" }, { "number": 3, "text": "aktuell" }],
      "correct": [false, true, false],
      "solution": "tats&auml;chlich",
      "explaination": "actually übersetzt sich zu tats&auml;chlich." }, { "question": "receipt", "answers": [{ "number": 1, "text": "&auml;rztliche Verschreibung" }, { "number": 2, "text": "Kochrezept" }, { "number": 3, "text": "Quittung" }],
      "correct": [false, false, true],
      "solution": "Quittung",
      "explaination": "Missing..." }, { "question": "lecture", "answers": [{ "number": 1, "text": "Vortrag" }, { "number": 2, "text": "Lektor/-in" }, { "number": 3, "text": "Lekt&uuml;re" }],
      "correct": [true, false, false],
      "solution": "Vortrag",
      "explaination": "lecture ist das englische Wort für Vortrag." }],
    "data": [],
    setup: (function () {
      this.set("showResult", false);
      this.set("current", 0);
      var handleOnThis = this;
      $.getJSON('backend/question2.php', function (serverData) {
        handleOnThis.set("data", serverData);
        handleOnThis.set("identifier", serverData['identifier']);
      });
    }).on('init'),
    actions: {
      evalQuestion1: function evalQuestion1(id) {
        if (id === "3") {
          this.set("showResult", false);
        } else {
          alert("probier es nochmal!");
        }
      },
      next: function next() {
        var curr = this.get("current");
        if (curr < 5) {
          this.set("current", curr + 1);
        }
      },
      back: function back() {
        var curr = this.get("current");
        if (curr > 1) {
          this.set("current", curr - 1);
        }
      },
      resetToDecision: function resetToDecision() {
        this.sendAction('selectedReset', 2);
      }
    }
  });
});