define("luera/components/type-four", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    choice1: 0,
    choice2: 0,
    choice3: 0,
    choice4: 0,
    choice5: 0,
    current: 1,
    "data": [{
      "label": "Fünf richtige Antworten hatten:",
      "value": 0.15,
      "type": "%",
      "key": 1
    }, {
      "label": "Vier richtige Antworten hatten:",
      "value": 0.25,
      "type": "%",
      "key": 2
    }, {
      "label": "Drei richtige Antworten hatten:",
      "value": 0.10,
      "formatLabel": "%",
      "key": 3
    }, {
      "label": "Zwei richtige Antworten hatten:",
      "value": 0.30,
      "type": "%",
      "key": 4
    }, {
      "label": "Eine richtige Antwort hatten:",
      "value": 0.15,
      "type": "%",
      "key": 5
    }, {
      "label": "Keine richtige Antwort hatten:",
      "value": 0.05,
      "type": "%",
      "key": 6
    }],
    setup: (function () {
      var handelOnThis = this;
      $.getJSON("backend/question4.php", function (serverData) {
        handelOnThis.set("data", serverData["data"]);
        handelOnThis.set("identifier", serverData["identifier"]);
      });
    }).on('init'),
    actions: {
      selectAnswer1: function selectAnswer1(value) {
        if (!this.get("evaluated")) {
          this.set("choice1", value);
        }
      },
      selectAnswer2: function selectAnswer2(value) {
        if (!this.get("evaluated")) {
          this.set("choice2", value);
        }
      },
      selectAnswer3: function selectAnswer3(value) {
        if (!this.get("evaluated")) {
          this.set("choice3", value);
        }
      },
      selectAnswer4: function selectAnswer4(value) {
        if (!this.get("evaluated")) {
          this.set("choice4", value);
        }
      },
      selectAnswer5: function selectAnswer5(value) {
        if (!this.get("evaluated")) {
          this.set("choice5", value);
        }
      },
      evaluate: function evaluate() {
        this.set("evaluated", true);
        var count = 0;
        if (this.get('choice1') === '4') {
          count++;
        }
        if (this.get('choice2') === '1') {
          count++;
        }
        if (this.get('choice3') === '2') {
          count++;
        }
        if (this.get('choice4') === '1') {
          count++;
        }
        if (this.get('choice5') === '4') {
          count++;
        }
        var payload = { "identifier": this.get("identifier"), "number": 1, "versuch": count };
        $.post("backend/question4.php", payload);
      },
      resetToDecision: function resetToDecision() {
        this.sendAction('selectedReset', 4);
        this.get("selectedReset")();
      }
    }
  });
});