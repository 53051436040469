define('luera/components/contact-comp', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    setupFunction: (function () {
      this.set('isSecretary', true);
      this.set('isRoute', false);
      this.set('isImp', false);
      this.set('isDSGVO', false);
    }).on('init'),
    actions: {
      activateSecretary: function activateSecretary() {
        this.set('isSecretary', true);
        this.set('isRoute', false);
        this.set('isImp', false);
        this.set('isDSGVO', false);
      },
      activateRoute: function activateRoute() {
        this.set('isSecretary', false);
        this.set('isRoute', true);
        this.set('isImp', false);
        this.set('isDSGVO', false);
      },
      activateImp: function activateImp() {
        this.set('isSecretary', false);
        this.set('isRoute', false);
        this.set('isImp', true);
        this.set('isDSGVO', false);
      },
      activateDSGVO: function activateDSGVO() {
        this.set('isSecretary', false);
        this.set('isRoute', false);
        this.set('isImp', false);
        this.set('isDSGVO', true);
      }
    }
  });
});