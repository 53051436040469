define('luera/helpers/data-select', ['exports'], function (exports) {
	exports.dataSelect = dataSelect;

	function dataSelect(param) {
		var answer = param[0];
		var id = param[1];
		return answer[id]['answer'];
	}

	exports['default'] = Ember.Helper.helper(dataSelect);
});