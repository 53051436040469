define('luera/components/draggable-item', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['draggable-item'],
    attributeBindings: ['draggable'],
    draggable: 'true',

    dragStart: function dragStart(event) {
      return event.dataTransfer.setData('Text', this.get('content'));
    }
  });
});