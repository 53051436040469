define("luera/components/question-typetwo", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    setUp: (function () {
      this.set("showResult", false);
      this.set("result1", false);
      this.set("result2", false);
      this.set("result3", false);
      this.set("active", true);
      this.set("lastChoice1", 0);
      this.set("lastChoice2", 0);
      this.set("lastChoice3", 0);
    }).on('init'),
    actions: {
      selectionToggled1: function selectionToggled1(choice) {
        if (this.get("active")) {
          this.set("lastChoice1", choice);
        }
      },
      selectionToggled2: function selectionToggled2(choice) {
        if (this.get("active")) {
          this.set("lastChoice2", choice);
        }
      },
      selectionToggled3: function selectionToggled3(choice) {
        if (this.get("active")) {
          this.set("lastChoice3", choice);
        }
      },
      evaluate: function evaluate(correct) {
        if (this.get("active")) {
          var number1 = this.get("lastChoice1");
          var number2 = this.get("lastChoice2");
          var number3 = this.get("lastChoice3");
          $(':radio:not(:checked)').attr('disabled', true);
          if (this.get("active")) {
            if (correct[0] === number1) {
              this.set("result1", true);
            }
            if (correct[1] === number2) {
              this.set("result2", true);
            }
            if (correct[2] === number3) {
              this.set("result3", true);
            }
            this.set("active", false);
            this.set("showResult", true);
            var complet = false;
            if (this.get('result1') && this.get('result2') && this.get('result3')) {
              complet = true;
            }
            var payload = { 'number': this.get('questionid'), 'versuch': complet, 'identifier': this.get('identifier') };
            $.post("backend/question2.php", payload);
          }
        }
      }
    }
  });
});