define("luera/components/type-three", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    setup: (function () {
      this.set("showHint", false);
      this.set("current", 0);
    }).on('init'),
    allowDrop: function allowDrop(ev) {
      ev.preventDefault();
    },
    actions: {
      next: function next() {
        var curr = this.get("current");
        this.set('showHint', false);
        if (curr < 5) {
          this.set("current", curr + 1);
        }
      },
      back: function back() {
        var curr = this.get("current");
        if (curr > 1) {
          this.set("current", curr - 1);
        }
      },
      resetToDecision: function resetToDecision() {
        this.sendAction('selectedReset', 3);
        this.get("selectedReset")();
      },
      moveItem: function moveItem(content) {
        var arrayContent = JSON.parse(content);
        var id = arrayContent[0];
        var correct = arrayContent[1];
        if (correct) {
          this.set("showHint", true);
          if (id == 1) {
            $('#goal').append($('#q1').text());
            $('#q1').remove();
          } else {
            $('#goal').append($('#q2').text());
            $('#q2').remove();
          }
        }
      }
    }
  });
});