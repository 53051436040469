define("luera/templates/components/question-typeone", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.11",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 20
              },
              "end": {
                "line": 7,
                "column": 114
              }
            },
            "moduleName": "luera/templates/components/question-typeone.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1, "src", "assets/images/guterSmilie2.svg");
            dom.setAttribute(el1, "width", "15px");
            dom.setAttribute(el1, "height", "15px");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.11",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 114
              },
              "end": {
                "line": 7,
                "column": 192
              }
            },
            "moduleName": "luera/templates/components/question-typeone.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("img");
            dom.setAttribute(el1, "src", "assets/images/schlechterSmilie2.svg");
            dom.setAttribute(el1, "width", "15px");
            dom.setAttribute(el1, "height", "15px");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.11",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 1
            },
            "end": {
              "line": 7,
              "column": 199
            }
          },
          "moduleName": "luera/templates/components/question-typeone.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "eq", [["get", "item.correct", ["loc", [null, [7, 30], [7, 42]]]], 1], [], ["loc", [null, [7, 26], [7, 45]]]]], [], 0, 1, ["loc", [null, [7, 20], [7, 199]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.11",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 20
              },
              "end": {
                "line": 9,
                "column": 114
              }
            },
            "moduleName": "luera/templates/components/question-typeone.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1, "src", "assets/images/guterSmilie2.svg");
            dom.setAttribute(el1, "width", "15px");
            dom.setAttribute(el1, "height", "15px");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.11",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 114
              },
              "end": {
                "line": 9,
                "column": 192
              }
            },
            "moduleName": "luera/templates/components/question-typeone.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("img");
            dom.setAttribute(el1, "src", "assets/images/schlechterSmilie2.svg");
            dom.setAttribute(el1, "width", "15px");
            dom.setAttribute(el1, "height", "15px");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.11",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 1
            },
            "end": {
              "line": 9,
              "column": 199
            }
          },
          "moduleName": "luera/templates/components/question-typeone.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "eq", [["get", "item.correct", ["loc", [null, [9, 30], [9, 42]]]], 2], [], ["loc", [null, [9, 26], [9, 45]]]]], [], 0, 1, ["loc", [null, [9, 20], [9, 199]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.11",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 20
              },
              "end": {
                "line": 11,
                "column": 114
              }
            },
            "moduleName": "luera/templates/components/question-typeone.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1, "src", "assets/images/guterSmilie2.svg");
            dom.setAttribute(el1, "width", "15px");
            dom.setAttribute(el1, "height", "15px");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.11",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 114
              },
              "end": {
                "line": 11,
                "column": 192
              }
            },
            "moduleName": "luera/templates/components/question-typeone.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("img");
            dom.setAttribute(el1, "src", "assets/images/schlechterSmilie2.svg");
            dom.setAttribute(el1, "width", "15px");
            dom.setAttribute(el1, "height", "15px");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.11",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 1
            },
            "end": {
              "line": 11,
              "column": 199
            }
          },
          "moduleName": "luera/templates/components/question-typeone.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "eq", [["get", "item.correct", ["loc", [null, [11, 30], [11, 42]]]], 3], [], ["loc", [null, [11, 26], [11, 45]]]]], [], 0, 1, ["loc", [null, [11, 20], [11, 199]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.11",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 20
              },
              "end": {
                "line": 13,
                "column": 114
              }
            },
            "moduleName": "luera/templates/components/question-typeone.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1, "src", "assets/images/guterSmilie2.svg");
            dom.setAttribute(el1, "width", "15px");
            dom.setAttribute(el1, "height", "15px");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.11",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 114
              },
              "end": {
                "line": 13,
                "column": 192
              }
            },
            "moduleName": "luera/templates/components/question-typeone.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("img");
            dom.setAttribute(el1, "src", "assets/images/schlechterSmilie2.svg");
            dom.setAttribute(el1, "width", "15px");
            dom.setAttribute(el1, "height", "15px");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.11",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 1
            },
            "end": {
              "line": 13,
              "column": 199
            }
          },
          "moduleName": "luera/templates/components/question-typeone.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "eq", [["get", "item.correct", ["loc", [null, [13, 30], [13, 42]]]], 4], [], ["loc", [null, [13, 26], [13, 45]]]]], [], 0, 1, ["loc", [null, [13, 20], [13, 199]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.11",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 22
              },
              "end": {
                "line": 23,
                "column": 53
              }
            },
            "moduleName": "luera/templates/components/question-typeone.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" Versuch ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.11",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 53
              },
              "end": {
                "line": 23,
                "column": 70
              }
            },
            "moduleName": "luera/templates/components/question-typeone.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" Versuche");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.11",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 1
            },
            "end": {
              "line": 29,
              "column": 0
            }
          },
          "moduleName": "luera/templates/components/question-typeone.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("p");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "style", "font-weight:bold;");
          var el2 = dom.createTextNode("Antwort:");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\nDas ist richtig! Zur Geschichte des Wortes: ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "style", "font-weight:bold;");
          var el2 = dom.createTextNode("Auswertung:");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\nSie haben ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" benötigt.\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\nZum Vergleich die Statistik:\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(4);
          morphs[0] = dom.createUnsafeMorphAt(fragment, 7, 7, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 16, 16, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 18, 18, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 22, 22, contextualElement);
          return morphs;
        },
        statements: [["content", "item.explaination", ["loc", [null, [19, 0], [19, 23]]]], ["content", "clicked", ["loc", [null, [23, 10], [23, 21]]]], ["block", "if", [["subexpr", "eq", [["get", "clicked", ["loc", [null, [23, 32], [23, 39]]]], 1], [], ["loc", [null, [23, 28], [23, 42]]]]], [], 0, 1, ["loc", [null, [23, 22], [23, 77]]]], ["inline", "horizontal-bar-chart", [], ["data", ["subexpr", "@mut", [["get", "data", ["loc", [null, [26, 28], [26, 32]]]]], [], []], "minBarThickness", 20, "maxBarThickness", 23, "sortKey", "key"], ["loc", [null, [26, 0], [26, 86]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.11",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 31,
            "column": 6
          }
        },
        "moduleName": "luera/templates/components/question-typeone.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "game1");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "questionbold");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "listitem");
        var el3 = dom.createElement("input");
        dom.setAttribute(el3, "type", "checkbox");
        dom.setAttribute(el3, "id", "1");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "listitem");
        var el3 = dom.createElement("input");
        dom.setAttribute(el3, "type", "checkbox");
        dom.setAttribute(el3, "id", "2");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "listitem");
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("input");
        dom.setAttribute(el3, "type", "checkbox");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "listitem");
        var el3 = dom.createElement("input");
        dom.setAttribute(el3, "type", "checkbox");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [9]);
        var element2 = dom.childAt(element1, [0]);
        var element3 = dom.childAt(element0, [12]);
        var element4 = dom.childAt(element3, [0]);
        var element5 = dom.childAt(element0, [15]);
        var element6 = dom.childAt(element5, [1]);
        var element7 = dom.childAt(element0, [18]);
        var element8 = dom.childAt(element7, [0]);
        var morphs = new Array(14);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [4]), 0, 0);
        morphs[1] = dom.createAttrMorph(element2, 'onclick');
        morphs[2] = dom.createUnsafeMorphAt(element1, 2, 2);
        morphs[3] = dom.createMorphAt(element1, 4, 4);
        morphs[4] = dom.createAttrMorph(element4, 'onclick');
        morphs[5] = dom.createUnsafeMorphAt(element3, 2, 2);
        morphs[6] = dom.createMorphAt(element3, 4, 4);
        morphs[7] = dom.createAttrMorph(element6, 'onclick');
        morphs[8] = dom.createUnsafeMorphAt(element5, 3, 3);
        morphs[9] = dom.createMorphAt(element5, 5, 5);
        morphs[10] = dom.createAttrMorph(element8, 'onclick');
        morphs[11] = dom.createUnsafeMorphAt(element7, 2, 2);
        morphs[12] = dom.createMorphAt(element7, 4, 4);
        morphs[13] = dom.createMorphAt(element0, 21, 21);
        return morphs;
      },
      statements: [["content", "item.question", ["loc", [null, [3, 25], [3, 42]]]], ["attribute", "onclick", ["subexpr", "action", ["clicked", 1, ["get", "item.correct", ["loc", [null, [6, 85], [6, 97]]]]], [], ["loc", [null, [6, 64], [6, 99]]]]], ["content", "item.answers.0.text", ["loc", [null, [6, 101], [6, 126]]]], ["block", "if", [["get", "showSmilie1", ["loc", [null, [7, 7], [7, 18]]]]], [], 0, null, ["loc", [null, [7, 1], [7, 206]]]], ["attribute", "onclick", ["subexpr", "action", ["clicked", 2, ["get", "item.correct", ["loc", [null, [8, 84], [8, 96]]]]], [], ["loc", [null, [8, 63], [8, 98]]]]], ["content", "item.answers.1.text", ["loc", [null, [8, 100], [8, 125]]]], ["block", "if", [["get", "showSmilie2", ["loc", [null, [9, 7], [9, 18]]]]], [], 1, null, ["loc", [null, [9, 1], [9, 206]]]], ["attribute", "onclick", ["subexpr", "action", ["clicked", 3, ["get", "item.correct", ["loc", [null, [10, 78], [10, 90]]]]], [], ["loc", [null, [10, 57], [10, 92]]]]], ["content", "item.answers.2.text", ["loc", [null, [10, 94], [10, 119]]]], ["block", "if", [["get", "showSmilie3", ["loc", [null, [11, 7], [11, 18]]]]], [], 2, null, ["loc", [null, [11, 1], [11, 206]]]], ["attribute", "onclick", ["subexpr", "action", ["clicked", 4, ["get", "item.correct", ["loc", [null, [12, 78], [12, 90]]]]], [], ["loc", [null, [12, 57], [12, 92]]]]], ["content", "item.answers.3.text", ["loc", [null, [12, 94], [12, 119]]]], ["block", "if", [["get", "showSmilie4", ["loc", [null, [13, 7], [13, 18]]]]], [], 3, null, ["loc", [null, [13, 1], [13, 206]]]], ["block", "if", [["get", "showResult", ["loc", [null, [14, 7], [14, 17]]]]], [], 4, null, ["loc", [null, [14, 1], [29, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});