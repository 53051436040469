define("luera/components/question-typeone", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    setUp: (function () {
      this.set("clicked", 0);
      this.set("showSmilie1", false);
      this.set("showSmilie2", false);
      this.set("showSmilie3", false);
      this.set("showSmilie4", false);
      this.set("active", true);
    }).on('init'),
    actions: {
      clicked: function clicked(number, correct) {
        var validCounter = this.get("clicked");
        if (this.get("active")) {
          if (number === 1 && this.get("showSmilie1") === false) {
            this.set("showSmilie1", true);
            validCounter += 1;
            this.set("clicked", validCounter);
          }
          if (number === 2 && this.get("showSmilie2") === false) {
            this.set("showSmilie2", true);
            validCounter += 1;
            this.set("clicked", validCounter);
          }
          if (number === 3 && this.get("showSmilie3") === false) {
            this.set("showSmilie3", true);
            validCounter += 1;
            this.set("clicked", validCounter);
          }
          if (number === 4 && this.get("showSmilie4") === false) {
            this.set("showSmilie4", true);
            validCounter += 1;
            this.set("clicked", validCounter);
          }
        }
        if (number === correct) {
          this.set("active", false);
          this.set("showResult", true);
          var payload = { 'number': this.get("questionid"), 'versuche': this.get('clicked'), 'identifier': this.get('identifier') };
          $.post('backend/question1.php', payload);
        }
      }
    }
  });
});