define("luera/controllers/application", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Controller.extend({
		"excercise": {
			"question": "Welches Jahr haben wir gerade?",
			"answers": ["2001", "2002", "2010", "2016", "2015"],
			"correct": "2015"
		},
		actions: {
			toggleCollapse: function toggleCollapse() {
				_ember["default"].$(this.get('collapse1').toggle());
			}
		}
	});
});