define('luera/app', ['exports', 'ember', 'ember-data', 'ember/resolver', 'ember/load-initializers', 'luera/config/environment'], function (exports, _ember, _emberData, _emberResolver, _emberLoadInitializers, _lueraConfigEnvironment) {

	var App = undefined;

	_ember['default'].MODEL_FACTORY_INJECTIONS = true;

	App = _ember['default'].Application.extend({
		modulePrefix: _lueraConfigEnvironment['default'].modulePrefix,
		podModulePrefix: _lueraConfigEnvironment['default'].podModulePrefix,
		Resolver: _emberResolver['default']
	});

	App.IndexRoute = _ember['default'].Route.extend({
		redirect: function redirect() {
			this.transitionTo('main');
		}
	});

	App.question1result = _emberData['default'].Model.extend({
		answer: _emberData['default'].hasMany('q1answer'),
		question: _emberData['default'].attr("number")
	});
	App.q1answer = _emberData['default'].Model.extend({
		key: _emberData['default'].attr("number"),
		value: _emberData['default'].attr("number"),
		label: _emberData['default'].attr("string")
	});
	(0, _emberLoadInitializers['default'])(App, _lueraConfigEnvironment['default'].modulePrefix);

	exports['default'] = App;
});