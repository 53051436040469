define("luera/components/type-one", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    "catalog": [{ "question": "Mädchen", "answers": [{ "number": 1, "text": "Mutter" }, { "number": 2, "text": "Mandel" }, { "number": 3, "text": "Magd" }, { "number": 4, "text": "Made" }],
      "correct": 3,
      "explaination": "<i>M&auml;dchen</i> stammt von der Verkleinerungsform <i>M&auml;gdchen</i> und damit von <i>Magd</i>."
    }, { "question": "Urlaub", "answers": [{ "number": 1, "text": "Erlaubnis" }, { "number": 2, "text": "Laube" }, { "number": 3, "text": "Erlebnis" }, { "number": 4, "text": "Verlobung" }],
      "correct": 1,
      "explaination": "<i>Urlaub</i> stammt vom althochdeutschen <i>urloub</i> ab. <br>Dieses bedeutet <i>Erlaubnis</i>, sich zu entfernen." }, { "question": "Hefe", "answers": [{ "number": 1, "text": "helfen" }, { "number": 2, "text": "heben" }, { "number": 3, "text": "hoffen" }, { "number": 4, "text": "haben" }],
      "correct": 2,
      "explaination": "Durch die Hefe soll der Teig gären und sich <i>heben</i>.<br> Das althochdeutsche Verb <i>heffen</i> (= heben, treiben) wurde <br>zum mittelhochdeutschen <i>heben/heven</i> (davon stammt auch das Verb <i>hieven</i> ab). <br>Das zugehörige Nomen lautet althochdeutsch <i>heva/heffa</i><br>und mittelhochdeutsch <i>hebe/hefe/heve</i>." }, { "question": "Karneval", "answers": [{ "number": 2, "text": "Karpfen" }, { "number": 3, "text": "Maske" }, { "number": 1, "text": "Tal" }, { "number": 4, "text": "Fleisch" }],
      "correct": 4,
      "explaination": "<i>Karneval</i> entstand aus dem italienischen <i>carnevale</i> bzw. dem lateinischen <i>carne vale! = Leb wohl, Fleisch!</i><br>Vor dem Beginn der 40-t&auml;gigen Fastenzeit, die oft mit Fleischverzicht einherging, wird noch einmal ausgiebig gefeiert." }, { "question": "Laune", "answers": [{ "number": 1, "text": "Alaun" }, { "number": 2, "text": "Mond" }, { "number": 3, "text": "Lagune" }, { "number": 4, "text": "L&uuml;ge" }],
      "correct": 2,
      "explaination": "<i>Laune</i> entstand aus dem lateinischen <i>luna = Mond</i>. <br>Früher galt der Mond mit seiner sich ständig verändernden Gestalt als Sinnbild für Wechselhaftigkeit, außerdem wurde ihm Einfluss auf die menschlichen Körpersäfte <br>und damit auf die Stimmungen nachgesagt." }, { "question": "Abenteuer", "answers": [{ "number": 1, "text": "Avancen" }, { "number": 2, "text": "Abwandlung" }, { "number": 3, "text": "Abend" }, { "number": 4, "text": "Advent" }],
      "correct": 4,
      "explaination": "Vom lateinischen Verb <i>advenire</i> (= herankommen) und dem daraus abgeleiteten Nomen <i>adventura</i> (= das auf einen Zukommende, also ein zukünftiges Ereignis) stammt das französische <i>aventure</i>. Dieses ging im 12. Jahrhundert als <i>abentiure</i> ins Mittelhochdeutsche ein. <i>Abenteuer</i> ist also mit dem <i>Advent</i> verwandt, der als <i>Adventus Domini</i> auf die bevorstehende Ankunft des Herrn, also die Geburt Christi, hinweist." }],
    "dataBase": [{
      "label": "bei erster Antwort richtig",
      "value": 0,
      "key": 1
    }, {
      "label": "bei zweiter Antwort richtig",
      "value": 0,
      "key": 2
    }, {
      "label": "bei dritter Antwort richtig",
      "value": 0,
      "key": 3
    }, {
      "label": "bei vierter Antwort richtig",
      "value": 0,
      "key": 4
    }],
    data1: {},
    setup: (function () {
      this.set("showResult", false);
      this.set("current", 0);
      var handleOnThis = this;
      $.getJSON("backend/question1.php", function (serverData) {
        handleOnThis.set('key', serverData['identifier']);
        handleOnThis.set('data1', serverData);
      });
    }).on('init'),
    actions: {
      data: function data() {
        return [{
          "label": "bei erster Antwort richtig",
          "value": 0,
          "key": 1
        }, {
          "label": "bei zweiter Antwort richtig",
          "value": 0,
          "key": 2
        }, {
          "label": "bei dritter Antwort richtig",
          "value": 0,
          "key": 3
        }, {
          "label": "bei vierter Antwort richtig",
          "value": 0,
          "key": 4
        }];
      },
      evalQuestion1: function evalQuestion1(id) {
        console.log("hallo");
        console.log(id);
        if (id === "3") {
          this.set("showResult", false);
        } else {
          alert("probier es nochmal!");
        }
      },
      next: function next() {
        var curr = this.get("current");
        if (curr < 6) {
          this.set("current", curr + 1);
        }
      },
      back: function back() {
        var curr = this.get("current");
        if (curr > 1) {
          this.set("current", curr - 1);
        }
      },
      resetToDecision: function resetToDecision() {
        this.get("selectedReset")();
      }
    }
  });
});