define("luera/components/type-five", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Component.extend({
        helpOff: true,
        active: true,
        showResult: false,
        setup: (function () {
            //erste Wort
            this.set("feld11", "");
            this.set("feld12", "");
            this.set("feld13", "");
            this.set("feld14", "");
            this.set("feld15", "");
            this.set("feld16", "");
            this.set("feld17", "");
            this.set("feld18", "");
            this.set("feld19", "");
            //zweite Wort
            this.set("feld21", "");
            this.set("feld22", "");
            this.set("feld23", "");
            this.set("feld24", "");
            this.set("feld25", "");
            this.set("feld26", "");
            this.set("feld27", "");
            this.set("feld28", "");
            this.set("feld29", "");
            this.set("feld210", "");
            //dritte Wort
            this.set("feld31", "");
            this.set("feld32", "");
            this.set("feld33", "");
            this.set("feld34", "");
            this.set("feld35", "");
            this.set("feld36", "");
            this.set("feld37", "");
            this.set("feld38", "");
            this.set("feld39", "");
            this.set("feld310", "");
            this.set("feld311", "");
            this.set("feld312", "");
            this.set("feld313", "");

            //viertes Wort
            this.set("feld41", "");
            this.set("feld42", "");
            this.set("feld43", "");
            this.set("feld44", "");
            this.set("feld45", "");
            this.set("feld46", "");
            this.set("feld47", "");
            this.set("feld48", "");
            this.set("feld49", "");
            this.set("feld410", "");

            //fuenftes Wort
            this.set("feld51", "");
            this.set("feld52", "");
            this.set("feld53", "");
            this.set("feld54", "");
            this.set("feld55", "");
            this.set("feld56", "");
            this.set("feld57", "");
            this.set("feld58", "");
            this.set("feld59", "");
            this.set("feld510", "");
            this.set("feld511", "");
            this.set("feld512", "");
            this.set("feld513", "");

            //sechstes Wort
            this.set("feld61", "");
            this.set("feld62", "");
            this.set("feld63", "");
            this.set("feld64", "");
            this.set("feld65", "");
            this.set("feld66", "");
            this.set("feld67", "");
            this.set("feld68", "");
            this.set("feld69", "");

            //siebtes Wort
            this.set("feld71", "");
            this.set("feld72", "");
            this.set("feld73", "");
            this.set("feld74", "");
            this.set("feld75", "");
            this.set("feld76", "");
            this.set("feld77", "");
            this.set("feld78", "");
            this.set("feld79", "");
            this.set("feld710", "");
        }).on('init'),
        actions: {
            toggleHelp: function toggleHelp() {
                this.set("helpOff", !this.get("helpOff"));
            },
            resetToDecision: function resetToDecision() {
                this.get("selectedReset")(5);
            },
            color1: function color1() {
                if (this.get("wort1complete")) {
                    if (this.get("word1richtig")) {
                        $('#f11').css("background-color", "lightgreen");
                        $('#f12').css("background-color", "lightgreen");
                        $('#f13').css("background-color", "lightgreen");
                        $('#f14').css("background-color", "lightgreen");
                        $('#f15').css("background-color", "lightgreen");
                        $('#f16').css("background-color", "lightgreen");
                        $('#f17').css("background-color", "lightgreen");
                        $('#f18').css("background-color", "lightgreen");
                        $('#f19').css("background-color", "lightgreen");
                    } else {
                        $('#f11').css("background-color", "lightcoral");
                        $('#f12').css("background-color", "lightcoral");
                        $('#f13').css("background-color", "lightcoral");
                        $('#f14').css("background-color", "lightcoral");
                        $('#f15').css("background-color", "lightcoral");
                        $('#f16').css("background-color", "lightcoral");
                        $('#f17').css("background-color", "lightcoral");
                        $('#f18').css("background-color", "lightcoral");
                        $('#f19').css("background-color", "lightcoral");
                    }
                } else {
                    $('#f11').css("background-color", "white");
                    $('#f12').css("background-color", "white");
                    $('#f13').css("background-color", "white");
                    $('#f14').css("background-color", "white");
                    $('#f15').css("background-color", "white");
                    $('#f16').css("background-color", "white");
                    $('#f17').css("background-color", "white");
                    $('#f18').css("background-color", "white");
                    $('#f19').css("background-color", "white");
                }
            },
            color2: function color2() {
                if (this.get("wort2complete")) {
                    if (this.get("word2richtig")) {
                        $('#f21').css("background-color", "lightgreen");
                        $('#f22').css("background-color", "lightgreen");
                        $('#f23').css("background-color", "lightgreen");
                        $('#f24').css("background-color", "lightgreen");
                        $('#f25').css("background-color", "lightgreen");
                        $('#f26').css("background-color", "lightgreen");
                        $('#f27').css("background-color", "lightgreen");
                        $('#f28').css("background-color", "lightgreen");
                        $('#f29').css("background-color", "lightgreen");
                        $('#f210').css("background-color", "lightgreen");
                    } else {
                        $('#f21').css("background-color", "lightcoral");
                        $('#f22').css("background-color", "lightcoral");
                        $('#f23').css("background-color", "lightcoral");
                        $('#f24').css("background-color", "lightcoral");
                        $('#f25').css("background-color", "lightcoral");
                        $('#f26').css("background-color", "lightcoral");
                        $('#f27').css("background-color", "lightcoral");
                        $('#f28').css("background-color", "lightcoral");
                        $('#f29').css("background-color", "lightcoral");
                        $('#f210').css("background-color", "lightcoral");
                    }
                } else {
                    $('#f21').css("background-color", "white");
                    $('#f22').css("background-color", "white");
                    $('#f23').css("background-color", "white");
                    $('#f24').css("background-color", "white");
                    $('#f25').css("background-color", "white");
                    $('#f26').css("background-color", "white");
                    $('#f27').css("background-color", "white");
                    $('#f28').css("background-color", "white");
                    $('#f29').css("background-color", "white");
                    $('#f210').css("background-color", "white");
                }
            },
            color3: function color3() {
                if (this.get("wort3complete")) {
                    if (this.get("word3richtig")) {
                        $('#f31').css("background-color", "lightgreen");
                        $('#f32').css("background-color", "lightgreen");
                        $('#f33').css("background-color", "lightgreen");
                        $('#f34').css("background-color", "lightgreen");
                        $('#f35').css("background-color", "lightgreen");
                        $('#f36').css("background-color", "lightgreen");
                        $('#f37').css("background-color", "lightgreen");
                        $('#f38').css("background-color", "lightgreen");
                        $('#f39').css("background-color", "lightgreen");
                        $('#f310').css("background-color", "lightgreen");
                        $('#f311').css("background-color", "lightgreen");
                        $('#f312').css("background-color", "lightgreen");
                        $('#f313').css("background-color", "lightgreen");
                    } else {
                        $('#f31').css("background-color", "lightcoral");
                        $('#f32').css("background-color", "lightcoral");
                        $('#f33').css("background-color", "lightcoral");
                        $('#f34').css("background-color", "lightcoral");
                        $('#f35').css("background-color", "lightcoral");
                        $('#f36').css("background-color", "lightcoral");
                        $('#f37').css("background-color", "lightcoral");
                        $('#f38').css("background-color", "lightcoral");
                        $('#f39').css("background-color", "lightcoral");
                        $('#f310').css("background-color", "lightcoral");
                        $('#f311').css("background-color", "lightcoral");
                        $('#f312').css("background-color", "lightcoral");
                        $('#f313').css("background-color", "lightcoral");
                    }
                } else {
                    $('#f31').css("background-color", "white");
                    $('#f32').css("background-color", "white");
                    $('#f33').css("background-color", "white");
                    $('#f34').css("background-color", "white");
                    $('#f35').css("background-color", "white");
                    $('#f36').css("background-color", "white");
                    $('#f37').css("background-color", "white");
                    $('#f38').css("background-color", "white");
                    $('#f39').css("background-color", "white");
                    $('#f310').css("background-color", "white");
                    $('#f311').css("background-color", "white");
                    $('#f312').css("background-color", "white");
                    $('#f313').css("background-color", "white");
                }
            },
            color4: function color4() {
                if (this.get("wort4complete")) {
                    if (this.get("word4richtig")) {
                        $('#f41').css("background-color", "lightgreen");
                        $('#f42').css("background-color", "lightgreen");
                        $('#f43').css("background-color", "lightgreen");
                        $('#f44').css("background-color", "lightgreen");
                        $('#f45').css("background-color", "lightgreen");
                        $('#f46').css("background-color", "lightgreen");
                        $('#f47').css("background-color", "lightgreen");
                        $('#f48').css("background-color", "lightgreen");
                        $('#f49').css("background-color", "lightgreen");
                        $('#f410').css("background-color", "lightgreen");
                    } else {
                        $('#f41').css("background-color", "lightcoral");
                        $('#f42').css("background-color", "lightcoral");
                        $('#f43').css("background-color", "lightcoral");
                        $('#f44').css("background-color", "lightcoral");
                        $('#f45').css("background-color", "lightcoral");
                        $('#f46').css("background-color", "lightcoral");
                        $('#f47').css("background-color", "lightcoral");
                        $('#f48').css("background-color", "lightcoral");
                        $('#f49').css("background-color", "lightcoral");
                        $('#f410').css("background-color", "lightcoral");
                    }
                } else {
                    $('#f41').css("background-color", "white");
                    $('#f42').css("background-color", "white");
                    $('#f43').css("background-color", "white");
                    $('#f44').css("background-color", "white");
                    $('#f45').css("background-color", "white");
                    $('#f46').css("background-color", "white");
                    $('#f47').css("background-color", "white");
                    $('#f48').css("background-color", "white");
                    $('#f49').css("background-color", "white");
                    $('#f410').css("background-color", "white");
                }
            },
            color5: function color5() {
                if (this.get("wort5complete")) {
                    if (this.get("word5richtig")) {
                        $('#f51').css("background-color", "lightgreen");
                        $('#f52').css("background-color", "lightgreen");
                        $('#f53').css("background-color", "lightgreen");
                        $('#f54').css("background-color", "lightgreen");
                        $('#f55').css("background-color", "lightgreen");
                        $('#f56').css("background-color", "lightgreen");
                        $('#f57').css("background-color", "lightgreen");
                        $('#f58').css("background-color", "lightgreen");
                        $('#f59').css("background-color", "lightgreen");
                        $('#f510').css("background-color", "lightgreen");
                        $('#f511').css("background-color", "lightgreen");
                        $('#f512').css("background-color", "lightgreen");
                        $('#f513').css("background-color", "lightgreen");
                    } else {
                        $('#f51').css("background-color", "lightcoral");
                        $('#f52').css("background-color", "lightcoral");
                        $('#f53').css("background-color", "lightcoral");
                        $('#f54').css("background-color", "lightcoral");
                        $('#f55').css("background-color", "lightcoral");
                        $('#f56').css("background-color", "lightcoral");
                        $('#f57').css("background-color", "lightcoral");
                        $('#f58').css("background-color", "lightcoral");
                        $('#f59').css("background-color", "lightcoral");
                        $('#f510').css("background-color", "lightcoral");
                        $('#f511').css("background-color", "lightcoral");
                        $('#f512').css("background-color", "lightcoral");
                        $('#f513').css("background-color", "lightcoral");
                    }
                } else {
                    $('#f51').css("background-color", "white");
                    $('#f52').css("background-color", "white");
                    $('#f53').css("background-color", "white");
                    $('#f54').css("background-color", "white");
                    $('#f55').css("background-color", "white");
                    $('#f56').css("background-color", "white");
                    $('#f57').css("background-color", "white");
                    $('#f58').css("background-color", "white");
                    $('#f59').css("background-color", "white");
                    $('#f510').css("background-color", "white");
                    $('#f511').css("background-color", "white");
                    $('#f512').css("background-color", "white");
                    $('#f513').css("background-color", "white");
                }
            },
            color6: function color6() {
                if (this.get("wort6complete")) {
                    if (this.get("word6richtig")) {
                        $('#f61').css("background-color", "lightgreen");
                        $('#f62').css("background-color", "lightgreen");
                        $('#f63').css("background-color", "lightgreen");
                        $('#f64').css("background-color", "lightgreen");
                        $('#f65').css("background-color", "lightgreen");
                        $('#f66').css("background-color", "lightgreen");
                        $('#f67').css("background-color", "lightgreen");
                        $('#f68').css("background-color", "lightgreen");
                        $('#f69').css("background-color", "lightgreen");
                    } else {
                        $('#f61').css("background-color", "lightcoral");
                        $('#f62').css("background-color", "lightcoral");
                        $('#f63').css("background-color", "lightcoral");
                        $('#f64').css("background-color", "lightcoral");
                        $('#f65').css("background-color", "lightcoral");
                        $('#f66').css("background-color", "lightcoral");
                        $('#f67').css("background-color", "lightcoral");
                        $('#f68').css("background-color", "lightcoral");
                        $('#f69').css("background-color", "lightcoral");
                    }
                } else {
                    $('#f61').css("background-color", "white");
                    $('#f62').css("background-color", "white");
                    $('#f63').css("background-color", "white");
                    $('#f64').css("background-color", "white");
                    $('#f65').css("background-color", "white");
                    $('#f66').css("background-color", "white");
                    $('#f67').css("background-color", "white");
                    $('#f68').css("background-color", "white");
                    $('#f69').css("background-color", "white");
                }
            },
            color7: function color7() {
                if (this.get("wort7complete")) {
                    if (this.get("word7richtig")) {
                        $('#f71').css("background-color", "lightgreen");
                        $('#f72').css("background-color", "lightgreen");
                        $('#f73').css("background-color", "lightgreen");
                        $('#f74').css("background-color", "lightgreen");
                        $('#f75').css("background-color", "lightgreen");
                        $('#f76').css("background-color", "lightgreen");
                        $('#f77').css("background-color", "lightgreen");
                        $('#f78').css("background-color", "lightgreen");
                        $('#f79').css("background-color", "lightgreen");
                        $('#f710').css("background-color", "lightgreen");
                    } else {
                        $('#f71').css("background-color", "lightcoral");
                        $('#f72').css("background-color", "lightcoral");
                        $('#f73').css("background-color", "lightcoral");
                        $('#f74').css("background-color", "lightcoral");
                        $('#f75').css("background-color", "lightcoral");
                        $('#f76').css("background-color", "lightcoral");
                        $('#f77').css("background-color", "lightcoral");
                        $('#f78').css("background-color", "lightcoral");
                        $('#f79').css("background-color", "lightcoral");
                        $('#f710').css("background-color", "lightcoral");
                    }
                } else {
                    $('#f71').css("background-color", "white");
                    $('#f72').css("background-color", "white");
                    $('#f73').css("background-color", "white");
                    $('#f74').css("background-color", "white");
                    $('#f75').css("background-color", "white");
                    $('#f76').css("background-color", "white");
                    $('#f77').css("background-color", "white");
                    $('#f78').css("background-color", "white");
                    $('#f79').css("background-color", "white");
                    $('#f710').css("background-color", "white");
                }
            },
            changeColor: function changeColor() {
                this.send("color1");
                this.send("color2");
                this.send("color3");
                this.send("color4");
                this.send("color5");
                this.send("color6");
                this.send("color7");
            },
            eval1: function eval1() {
                //Versalien
                if ((this.get("feld11") == "V" || this.get("feld11") == "v") && (this.get("feld12") == "E" || this.get("feld12") == "e") && (this.get("feld13") == "R" || this.get("feld13") == "r") && (this.get("feld14") == "S" || this.get("feld14") == "s") && (this.get("feld15") == "A" || this.get("feld15") == "a") && (this.get("feld16") == "L" || this.get("feld16") == "l") && (this.get("feld17") == "I" || this.get("feld17") == "i") && (this.get("feld18") == "E" || this.get("feld18") == "e") && (this.get("feld19") == "N" || this.get("feld19") == "n")) {
                    this.set("word1richtig", true);
                } else {
                    this.set("word1richtig", false);
                }
            },
            eval2: function eval2() {
                //Vakatseite
                if ((this.get("feld21") == "V" || this.get("feld21") == "v") && (this.get("feld22") == "A" || this.get("feld22") == "a") && (this.get("feld23") == "K" || this.get("feld23") == "k") && (this.get("feld24") == "A" || this.get("feld24") == "a") && (this.get("feld25") == "T" || this.get("feld25") == "t") && (this.get("feld26") == "S" || this.get("feld26") == "s") && (this.get("feld27") == "E" || this.get("feld27") == "e") && (this.get("feld28") == "I" || this.get("feld28") == "i") && (this.get("feld29") == "T" || this.get("feld29") == "t") && (this.get("feld210") == "E" || this.get("feld210") == "e")) {
                    this.set("word2richtig", true);
                } else {
                    this.set("word2richtig", false);
                }
            },
            eval3: function eval3() {
                //Geviertstrich
                if ((this.get("feld31") == "G" || this.get("feld31") == "g") && (this.get("feld32") == "E" || this.get("feld32") == "e") && (this.get("feld33") == "V" || this.get("feld33") == "v") && (this.get("feld34") == "I" || this.get("feld34") == "i") && (this.get("feld35") == "E" || this.get("feld35") == "e") && (this.get("feld36") == "R" || this.get("feld36") == "r") && (this.get("feld37") == "T" || this.get("feld37") == "t") && (this.get("feld38") == "S" || this.get("feld38") == "s") && (this.get("feld39") == "T" || this.get("feld39") == "t") && (this.get("feld310") == "R" || this.get("feld310") == "r") && (this.get("feld311") == "I" || this.get("feld311") == "i") && (this.get("feld312") == "C" || this.get("feld312") == "c") && (this.get("feld313") == "H" || this.get("feld313") == "h")) {
                    this.set("word3richtig", true);
                } else {
                    this.set("word3richtig", false);
                }
            },
            eval4: function eval4() {
                //Druckfahne
                if ((this.get("feld41") == "D" || this.get("feld41") == "d") && (this.get("feld42") == "R" || this.get("feld42") == "r") && (this.get("feld43") == "U" || this.get("feld43") == "u") && (this.get("feld44") == "C" || this.get("feld44") == "c") && (this.get("feld45") == "K" || this.get("feld45") == "k") && (this.get("feld46") == "F" || this.get("feld46") == "f") && (this.get("feld47") == "A" || this.get("feld47") == "a") && (this.get("feld48") == "H" || this.get("feld48") == "h") && (this.get("feld49") == "N" || this.get("feld49") == "n") && (this.get("feld410") == "E" || this.get("feld410") == "e")) {
                    this.set("word4richtig", true);
                } else {
                    this.set("word4richtig", false);
                }
            },
            eval5: function eval5() {
                //Schusterjunge
                if ((this.get("feld51") == "S" || this.get("feld51") == "s") && (this.get("feld52") == "C" || this.get("feld52") == "c") && (this.get("feld53") == "H" || this.get("feld53") == "h") && (this.get("feld54") == "U" || this.get("feld54") == "u") && (this.get("feld55") == "S" || this.get("feld55") == "s") && (this.get("feld56") == "T" || this.get("feld56") == "t") && (this.get("feld57") == "E" || this.get("feld57") == "e") && (this.get("feld58") == "R" || this.get("feld58") == "r") && (this.get("feld59") == "J" || this.get("feld59") == "j") && (this.get("feld510") == "U" || this.get("feld510") == "u") && (this.get("feld511") == "N" || this.get("feld511") == "n") && (this.get("feld512") == "G" || this.get("feld512") == "g") && (this.get("feld513") == "E" || this.get("feld513") == "e")) {
                    this.set("word5richtig", true);
                } else {
                    this.set("word5richtig", false);
                }
            },
            eval6: function eval6() {
                //Hurenkind
                if ((this.get("feld61") == "H" || this.get("feld61") == "h") && (this.get("feld62") == "U" || this.get("feld62") == "u") && (this.get("feld63") == "R" || this.get("feld63") == "r") && (this.get("feld64") == "E" || this.get("feld64") == "e") && (this.get("feld65") == "N" || this.get("feld65") == "n") && (this.get("feld66") == "K" || this.get("feld66") == "k") && (this.get("feld67") == "I" || this.get("feld67") == "i") && (this.get("feld68") == "N" || this.get("feld68") == "n") && (this.get("feld69") == "D" || this.get("feld69") == "d")) {
                    this.set("word6richtig", true);
                } else {
                    this.set("word6richtig", false);
                }
            },
            eval7: function eval7() {
                //Imprimatur
                if ((this.get("feld71") == "I" || this.get("feld71") == "i") && (this.get("feld72") == "M" || this.get("feld72") == "m") && (this.get("feld73") == "P" || this.get("feld73") == "p") && (this.get("feld74") == "R" || this.get("feld74") == "r") && (this.get("feld75") == "I" || this.get("feld75") == "i") && (this.get("feld76") == "M" || this.get("feld76") == "m") && (this.get("feld77") == "A" || this.get("feld77") == "a") && (this.get("feld78") == "T" || this.get("feld78") == "t") && (this.get("feld79") == "U" || this.get("feld79") == "u") && (this.get("feld710") == "R" || this.get("feld710") == "r")) {
                    this.set("word7richtig", true);
                } else {
                    this.set("word7richtig", false);
                }
            },
            evaluate: function evaluate() {
                if (this.get("feld11") !== "" && this.get("feld12") !== "" && this.get("feld13") !== "" && this.get("feld14") !== "" && this.get("feld15") !== "" && this.get("feld16") !== "" && this.get("feld17") !== "" && this.get("feld18") !== "" && this.get("feld19") !== "") {
                    this.set("wort1complete", true);
                    this.send("eval1");
                } else {
                    this.set("wort1complete", false);
                }

                if (this.get("feld21") !== "" && this.get("feld22") !== "" && this.get("feld23") !== "" && this.get("feld24") !== "" && this.get("feld25") !== "" && this.get("feld26") !== "" && this.get("feld27") !== "" && this.get("feld28") !== "" && this.get("feld29") !== "" && this.get("feld210") !== "") {
                    this.set("wort2complete", true);
                    this.send("eval2");
                } else {
                    this.set("wort2complete", false);
                }

                if (this.get("feld31") !== "" && this.get("feld32") !== "" && this.get("feld33") !== "" && this.get("feld34") !== "" && this.get("feld35") !== "" && this.get("feld36") !== "" && this.get("feld37") !== "" && this.get("feld38") !== "" && this.get("feld39") !== "" && this.get("feld310") !== "" && this.get("feld311") !== "" && this.get("feld312") !== "" && this.get("feld313") !== "") {
                    this.set("wort3complete", true);
                    this.send("eval3");
                } else {
                    this.set("wort3complete", false);
                }

                if (this.get("feld41") !== "" && this.get("feld42") !== "" && this.get("feld43") !== "" && this.get("feld44") !== "" && this.get("feld45") !== "" && this.get("feld46") !== "" && this.get("feld47") !== "" && this.get("feld48") !== "" && this.get("feld49") !== "" && this.get("feld410") !== "") {
                    this.set("wort4complete", true);
                    this.send("eval4");
                } else {
                    this.set("wort4complete", false);
                }

                if (this.get("feld51") !== "" && this.get("feld52") !== "" && this.get("feld53") !== "" && this.get("feld54") !== "" && this.get("feld55") !== "" && this.get("feld56") !== "" && this.get("feld57") !== "" && this.get("feld58") !== "" && this.get("feld59") !== "" && this.get("feld510") !== "" && this.get("feld511") !== "" && this.get("feld512") !== "" && this.get("feld513") !== "") {
                    this.set("wort5complete", true);
                    this.send("eval5");
                } else {
                    this.set("wort5complete", false);
                }

                if (this.get("feld61") !== "" && this.get("feld62") !== "" && this.get("feld63") !== "" && this.get("feld64") !== "" && this.get("feld65") !== "" && this.get("feld66") !== "" && this.get("feld67") !== "" && this.get("feld68") !== "" && this.get("feld69") !== "") {
                    this.set("wort6complete", true);
                    this.send("eval6");
                } else {
                    this.set("wort6complete", false);
                }

                if (this.get("feld71") !== "" && this.get("feld72") !== "" && this.get("feld73") !== "" && this.get("feld74") !== "" && this.get("feld75") !== "" && this.get("feld76") !== "" && this.get("feld77") !== "" && this.get("feld78") !== "" && this.get("feld79") !== "" && this.get("feld710") !== "") {
                    this.set("wort7complete", true);
                    this.send("eval7");
                } else {
                    this.set("wort7complete", false);
                }
                this.send("changeColor");
                if (this.get("word1richtig") && this.get("word2richtig") && this.get("word3richtig") && this.get("word5richtig") && this.get("word4richtig") && this.get("word6richtig") && this.get("word7richtig")) {
                    this.set("active", false);
                    this.set("showResult", true);
                }
            },
            onKeyDown: function onKeyDown(value, event) {
                var focusedElement = $(document.activeElement);
                if (this.get("active")) {
                    var feldNumber = focusedElement.attr("id").substring(1, 4);
                    //this.set("feld" + feldNumber, focusedElement.text());
                    this.set("feld" + feldNumber, event.key);
                    /*//erste Wort
                    this.set("feld11", $('#f11').text());
                    this.set("feld12",  $('#f12').text());
                    this.set("feld13",  $('#f13').text());
                    this.set("feld14",  $('#f14').text());
                    this.set("feld15",  $('#f15').text());
                    this.set("feld16",  $('#f16').text());
                    this.set("feld17",  $('#f17').text());
                    this.set("feld18",  $('#f18').text());
                    this.set("feld19",  $('#f19').text());
                    //zweite Wort
                    this.set("feld21", $('#f21').text());
                    this.set("feld22",  $('#f22').text());
                    this.set("feld23",  $('#f23').text());
                    this.set("feld24",  $('#f24').text());
                    this.set("feld25",  $('#f25').text());
                    this.set("feld26",  $('#f26').text());
                    this.set("feld27",  $('#f27').text());
                    this.set("feld28",  $('#f28').text());
                    this.set("feld29",  $('#f29').text());
                    this.set("feld210",  $('#f210').text());
                    //dritte Wort
                    this.set("feld31", $('#f31').text());
                    this.set("feld32",  $('#f32').text());
                    this.set("feld33",  $('#f33').text());
                    this.set("feld34",  $('#f34').text());
                    this.set("feld35",  $('#f35').text());
                    this.set("feld36",  $('#f36').text());
                    this.set("feld37",  $('#f37').text());
                    this.set("feld38",  $('#f38').text());
                    this.set("feld39",  $('#f39').text());
                    this.set("feld310",  $('#f310').text());
                    this.set("feld311",  $('#f311').text());
                    this.set("feld312",  $('#f312').text());
                    this.set("feld313",  $('#f313').text());
                     	//viertes Wort
                    this.set("feld41", $('#f41').text());
                    this.set("feld42",  $('#f42').text());
                    this.set("feld43",  $('#f43').text());
                    this.set("feld44",  $('#f44').text());
                    this.set("feld45",  $('#f45').text());
                    this.set("feld46",  $('#f46').text());
                    this.set("feld47",  $('#f47').text());
                    this.set("feld48",  $('#f48').text());
                    this.set("feld49",  $('#f49').text());
                    this.set("feld410",  $('#f410').text());
                    //fuenftes Wort
                    this.set("feld51", $('#f51').text());
                    this.set("feld52",  $('#f52').text());
                    this.set("feld53",  $('#f53').text());
                    this.set("feld54",  $('#f54').text());
                    this.set("feld55",  $('#f55').text());
                    this.set("feld56",  $('#f56').text());
                    this.set("feld57",  $('#f57').text());
                    this.set("feld58",  $('#f58').text());
                    this.set("feld59",  $('#f59').text());
                    this.set("feld510",  $('#f510').text());
                    this.set("feld511",  $('#f511').text());
                    this.set("feld512",  $('#f512').text());
                    this.set("feld513",  $('#f513').text());
                     	//sechstes Wort
                    this.set("feld61", $('#f61').text());
                    this.set("feld62",  $('#f62').text());
                    this.set("feld63",  $('#f63').text());
                    this.set("feld64",  $('#f64').text());
                    this.set("feld65",  $('#f65').text());
                    this.set("feld66",  $('#f66').text());
                    this.set("feld67",  $('#f67').text());
                    this.set("feld68",  $('#f68').text());
                    this.set("feld69",  $('#f69').text());
                    
                    //siebtes Wort
                    this.set("feld71", $('#f71').text());
                    this.set("feld72",  $('#f72').text());
                    this.set("feld73",  $('#f73').text());
                    this.set("feld74",  $('#f74').text());
                    this.set("feld75",  $('#f75').text());
                    this.set("feld76",  $('#f76').text());
                    this.set("feld77",  $('#f77').text());
                    this.set("feld78",  $('#f78').text());
                    this.set("feld79",  $('#f79').text());
                    this.set("feld710",  $('#f710').text());
                    */

                    //start Evaluation
                    var row = focusedElement.attr("id").substring(0, 2);
                    var newId = "#" + row + String(Number(focusedElement.attr("id").replace(row, "")) + 1);
                    if ($(newId).length && event.keyCode != 16 && event.keyCode != 9 && event.keyCode != 13 && event.keyCode != 8 && event.keyCode != 46 && (event.keyCode < 30 || event.keyCode > 40)) {
                        var next = $(newId);
                        setTimeout(function () {
                            next.focus();
                        }, 0);
                    }
                    this.send("evaluate");
                }
            }
        }
    });
});