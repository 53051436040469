define('luera/controllers/spiel', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    setupFunction: (function () {
      this.set('isDecision', true);
      this.set('isTypeOne', false);
      this.set('isTypeTwo', false);
      this.set('isTypeThree', false);
      this.set('isTypeFour', false);
      this.set('isTypeFive', false);
    }).on('init'),
    actions: {
      activateTypeOne: function activateTypeOne() {
        this.set('isDecision', false);
        this.set('isTypeOne', true);
        this.set('isTypeTwo', false);
        this.set('isTypeThree', false);
        this.set('isTypeFour', false);
        this.set('isTypeFive', false);
      },
      activateTypeTwo: function activateTypeTwo() {
        this.set('isDecision', false);
        this.set('isTypeOne', false);
        this.set('isTypeTwo', true);
        this.set('isTypeThree', false);
        this.set('isTypeFour', false);
        this.set('isTypeFive', false);
      },
      activateTypeThree: function activateTypeThree() {
        this.set('isDecision', false);
        this.set('isTypeOne', false);
        this.set('isTypeTwo', false);
        this.set('isTypeThree', true);
        this.set('isTypeFour', false);
        this.set('isTypeFive', false);
      },
      activateTypeFour: function activateTypeFour() {
        this.set('isDecision', false);
        this.set('isTypeOne', false);
        this.set('isTypeTwo', false);
        this.set('isTypeThree', false);
        this.set('isTypeFour', true);
        this.set('isTypeFive', false);
      },
      activateTypeFive: function activateTypeFive() {
        this.set('isDecision', false);
        this.set('isTypeOne', false);
        this.set('isTypeTwo', false);
        this.set('isTypeThree', false);
        this.set('isTypeFour', false);
        this.set('isTypeFive', true);
      },
      resetDecision: function resetDecision(id) {
        this.set('isDecision', true);
        this.set('isTypeOne', false);
        this.set('isTypeTwo', false);
        this.set('isTypeThree', false);
        this.set('isTypeFour', false);
        this.set('isTypeFive', false);
      }
    }
  });
});